import { render, staticRenderFns } from "./TablaLlamadas.vue?vue&type=template&id=dcedd48e&scoped=true"
import script from "./TablaLlamadas.vue?vue&type=script&lang=js"
export * from "./TablaLlamadas.vue?vue&type=script&lang=js"
import style0 from "./TablaLlamadas.vue?vue&type=style&index=0&id=dcedd48e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dcedd48e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VDataTable,VRow})
